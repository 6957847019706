@import url("./body.css");

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  font-family: "Gotham Bold", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.5s ease;
  z-index: 1;
}

.btn:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%,
    0 4px,
    4px 4px,
    4px calc(100% - 4px),
    calc(100% - 12px) calc(100% - 4px),
    calc(100% - 4px) calc(100% - 12px),
    calc(100% - 4px) 4px,
    0 4px
  );
  background: #fff;
  z-index: -1;
  opacity: 1;
}

.btn:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
  background: #fff;
  opacity: 0;
  z-index: -1;
}

.btn:hover {
  animation: turn-on--outline 1s;
  animation-fill-mode: forwards;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.33));
}

.btn:hover:before {
  animation: turn-on--outline-before 1s;
}

.btn:hover:after {
  animation: turn-on--outline-after 1s;
  animation-fill-mode: forwards;
}

@keyframes turn-on--outline {
  0% {
    color: #fff;
  }
  48% {
    color: #fff;
  }
  to {
    color: #000;
  }
}
@keyframes turn-on--outline-before {
  0% {
    opacity: 1;
  }
  8% {
    opacity: 0.5;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 0.5;
  }
  32% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  48% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@keyframes turn-on--outline-after {
  0% {
    opacity: 0;
  }
  48% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btnMarginTop {
  margin-top: 60px;
  margin-bottom: 0;
}

.card {
  padding: 80px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
