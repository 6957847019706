body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1)),
    url(/static/media/bg.ecc100d6.jpg) no-repeat center center fixed;
  background-size: cover;
  color: white;
  text-align: center;
}

* {
  margin: 0;
}

a {
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.assets_btn__2El_w {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  font-family: "Gotham Bold", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.5s ease;
  z-index: 1;
}

.assets_btn__2El_w:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%,
    0 4px,
    4px 4px,
    4px calc(100% - 4px),
    calc(100% - 12px) calc(100% - 4px),
    calc(100% - 4px) calc(100% - 12px),
    calc(100% - 4px) 4px,
    0 4px
  );
          clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%,
    0 4px,
    4px 4px,
    4px calc(100% - 4px),
    calc(100% - 12px) calc(100% - 4px),
    calc(100% - 4px) calc(100% - 12px),
    calc(100% - 4px) 4px,
    0 4px
  );
  background: #fff;
  z-index: -1;
  opacity: 1;
}

.assets_btn__2El_w:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
          clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
  background: #fff;
  opacity: 0;
  z-index: -1;
}

.assets_btn__2El_w:hover {
  -webkit-animation: assets_turn-on--outline__3cHdA 1s;
          animation: assets_turn-on--outline__3cHdA 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.33));
          filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.33));
}

.assets_btn__2El_w:hover:before {
  -webkit-animation: assets_turn-on--outline-before__3mor8 1s;
          animation: assets_turn-on--outline-before__3mor8 1s;
}

.assets_btn__2El_w:hover:after {
  -webkit-animation: assets_turn-on--outline-after__3m2-6 1s;
          animation: assets_turn-on--outline-after__3m2-6 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes assets_turn-on--outline__3cHdA {
  0% {
    color: #fff;
  }
  48% {
    color: #fff;
  }
  to {
    color: #000;
  }
}

@keyframes assets_turn-on--outline__3cHdA {
  0% {
    color: #fff;
  }
  48% {
    color: #fff;
  }
  to {
    color: #000;
  }
}
@-webkit-keyframes assets_turn-on--outline-before__3mor8 {
  0% {
    opacity: 1;
  }
  8% {
    opacity: 0.5;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 0.5;
  }
  32% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  48% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
@keyframes assets_turn-on--outline-before__3mor8 {
  0% {
    opacity: 1;
  }
  8% {
    opacity: 0.5;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 0.5;
  }
  32% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  48% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes assets_turn-on--outline-after__3m2-6 {
  0% {
    opacity: 0;
  }
  48% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes assets_turn-on--outline-after__3m2-6 {
  0% {
    opacity: 0;
  }
  48% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.assets_btnMarginTop__149tS {
  margin-top: 60px;
  margin-bottom: 0;
}

.assets_card__1Xbds {
  padding: 80px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.assets_root__1r50U {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

