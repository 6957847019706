body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1)),
    url("bg.jpg") no-repeat center center fixed;
  background-size: cover;
  color: white;
  text-align: center;
}

* {
  margin: 0;
}

a {
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
}
